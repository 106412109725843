import Vue, { CreateElement, VNode } from 'vue';
// Required by VuejsClipper
import VueRx from 'vue-rx';
import InfiniteLoading from 'vue-infinite-loading';
import { BootstrapVue } from 'bootstrap-vue';
import vClickOutside from 'v-click-outside';
import VueMask from 'v-mask';
import VueSecureHTML from 'vue-html-secure';
import VuejsClipper from 'vuejs-clipper';
import panZoom from 'vue-panzoom';
import VueNumber from 'vue-number-animation';
import checkView from 'vue-check-view';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueMeta from 'vue-meta';
import i18n from '@/plugins/locale/I18n';
import App from '@/App.vue';
import '@/plugins/registerServiceWorker';
import { createRouter } from '@/router';
import store from '@/store';
import toolNotificationDirective from '@/directives/ToolNotificationDirectives';
import ClientStorage from '@/utils/ClientStore';
import LocaleService from '@/services/LocaleService';
import EventBus, { EventsBus } from '@/utils/event-bus/EventsBus';
import Logger, { logger } from '@/utils/logger/Logger';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import GUUID from '@/utils/GUUID';
import axios from 'axios';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import CookieService from '@/services/CookieService';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import jwtDecode from 'jwt-decode';
import JwtParams from '@/utils/types/JwtParams';
import { Route } from 'vue-router';
import Gdpr from '@/utils/constants/Gdpr';
import EntityType from '@/utils/enums/EntityType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import '@/assets/styles/config/Main.scss';
import AccountRepository from '@/repositories/AccountRepository';

Vue.use(BootstrapVue);
Vue.use(VueSecureHTML);
Vue.use(VueNumber);
Vue.use(checkView);
Vue.use(vueVimeoPlayer);
Vue.use(logger);

Vue.prototype.$http = axios;
Vue.prototype.FileResourceHelper = FileResourceHelper;
Vue.prototype.DateTimeHelper = DateTimeHelper;

Vue.use(InfiniteLoading);
Vue.use(vClickOutside);
Vue.use(VueMask, {
  placeholders: {
    D: /[ap]/,
  },
});
Vue.use(VueRx);
Vue.use(VuejsClipper);
Vue.use(panZoom);

Vue.use(EventsBus);

Vue.use(VueMeta);

Vue.config.productionTip = false;

store.state.i18n = i18n;

Vue.directive('app-tt-modal-show', toolNotificationDirective);

const params = new URLSearchParams(window.location.search);
const ver = params.get('version') || '';
if (ver) {
  ClientStorage.setItem('version', ver);
} else {
  ClientStorage.removeItem('version');
}

if (!ClientStorage.getItem('device-id')) {
  ClientStorage.setItem('device-id', GUUID.uuidv4());
}

const logout = (): Promise<void> => store.dispatch('AuthenticationStore/logout');

const getAuthenticationStatus = async (to: Route): Promise<boolean> => {
  if (to.query.token) {
    const isJwtVerified = await new AccountRepository().verifyToken(to.query.token as string);
    if (isJwtVerified) {
      CookieService.deleteCookie(AUTH_TOKEN);
      store.commit('setAuthToken', { token: to.query.token });
    }
  }

  const token = CookieService.getCookie(AUTH_TOKEN);
  if (token === null) {
    return logout()
      .then(() => Promise.resolve(false));
  }
  const decodedToken = jwtDecode(token) as JwtParams;
  return Promise.resolve(decodedToken.r !== 'GUEST');
};

const $logger = new Logger();
const $eventBus = new EventBus();

function logStats(to: Route, from: Route): void {
  $logger.log(['setDocumentTitle', to.name]);
  $logger.log(['setReferrerUrl', `${window.location.origin}${from.fullPath || ''}`]);
  $logger.log(['setCustomUrl', `${window.location.origin}${to.fullPath || ''}`]);
  $logger.log(['trackPageView']);
}

store.dispatch('load')
  .finally(() => {
    const router = createRouter(store.state.pages, logStats, store.getters.isUnifyExhibitorPortal);
    let promise = Promise.resolve();
    if (store.state.community) {
      if (window && window.broadstreet && window.broadstreet.watch) {
        const gdprSettings = JSON.parse(ClientStorage.getItem(Gdpr) as string);
        window.broadstreet = window.broadstreet || { run: [] };
        window.broadstreet.run.push(() => {
          window.broadstreet.watch({
            callback: (zoneId: string) => {
              $eventBus.emit('validate-advertise', zoneId);
            },
            networkId: store.state.community?.advertisementNetworkId,
            gdpr: gdprSettings ? gdprSettings.advertising : false,
          });
        });
      }
      // Todo verify the validity of the token every certain time
      router.beforeEach((to, from, next) => {
        const breadCrumb: string[] = [];
        const pathParts = to.path.split('/')
          .filter((part) => part.trim() !== '');
        if (!to.matched.length) {
          next(router.resolve({ name: 'not-found' }).location);
          return;
        }

        Promise.all(pathParts.map((part) => {
          breadCrumb.push(part);
          const resolved = router.resolve(`/${breadCrumb.join('/')}`);
          Object.assign(to.meta, {
            entityName: null,
            entityDescription: null,
          });
          if (resolved.route.name !== 'not-found') {
            const foundInParams = Object.keys(resolved.route.params)
              .find((param) => resolved.route.params[param] === part);
            if (foundInParams && resolved.route.meta && resolved.route.meta.entityType) {
              if (resolved.route.meta.entityType === 'feedItemWrapper') {
                return 'Post';
              }

              if ([EntityType.COMPANY_CMS, EntityType.SPEAKER_CMS, EntityType.SESSION_CMS]
                .includes(resolved.route.meta.entityType)) {
                return part;
              }

              return store.dispatch('configuration/loadBreadcrumbForStatLogger', {
                type: resolved.route.meta.entityType,
                uid: part,
              })
                .then((response) => {
                  if (resolved.route.meta
                    && response[resolved.route.meta.entityType]) {
                    // eslint-disable-next-line max-len
                    const data = response[resolved.route.meta.entityType] as Record<string, string>[];
                    if (data.length > 0) {
                      Object.assign(to.meta, { entityName: data[0].name || data[0].title });
                      if (data[0].description) {
                        Object.assign(to.meta, { entityDescription: data[0].description });
                      }
                      return data[0].name;
                    }
                    return '';
                  }
                  return '';
                });
            }
            return part;
          }
          return '';
        }))
          .then((response) => {
            const result = response.filter((part) => part && part.trim() !== '')
              .join(' - ') || 'home';
            Object.assign(to.meta, { breadcrumb: result });
            logStats(to, from);
          })
          .finally(async () => {
            // eslint-disable-next-line max-len
            const isCommunityEnhancedLoginFeatureEnabled = store.getters.featureByKey(FeatureKeys.COMMUNITY_ENABLE_ENHANCED_LOGIN)
              && store.getters.featureByKey(FeatureKeys.COMMUNITY_ENABLE_ENHANCED_LOGIN).enabled;

            if (to.name === 'reset-password' || to.name === 'register') {
              logout()
                .then(() => {
                  if (to.name === 'reset-password'
                    && isCommunityEnhancedLoginFeatureEnabled) {
                    next({
                      name: 'create-password',
                      params: to.params,
                    });
                  }
                  next();
                });
            }

            const isAuthenticated = await getAuthenticationStatus(to);
            if (isAuthenticated) {
              if (!store.state.authUser) {
                if (isCommunityEnhancedLoginFeatureEnabled) {
                  await store.dispatch('SignInStore/login');
                } else {
                  await store.dispatch('AuthenticationStore/login');
                }
              }
            } else {
              store.state.authUser = null;
            }

            const userManagementPage = pathParts.includes('email-disambiguated')
              || pathParts.includes('create-password')
              || pathParts.includes('enter-information');
            if (isCommunityEnhancedLoginFeatureEnabled) {
              const { authUser } = store.getters;
              if (authUser
                // eslint-disable-next-line no-underscore-dangle
                && (authUser._needsActivation === true || authUser._needsActivation === null)) {
                // eslint-disable-next-line no-underscore-dangle
                if (authUser._needsEmailDisambiguated && !userManagementPage) {
                  next({ name: 'email-disambiguated' });
                  // eslint-disable-next-line no-underscore-dangle
                } else if (authUser._needsPasswordCreated && !userManagementPage) {
                  next({ name: 'create-password' });
                  // eslint-disable-next-line no-underscore-dangle
                } else if (authUser._needsNameCreated && !userManagementPage) {
                  next({ name: 'enter-information' });
                }
              }
            }

            if (isCommunityEnhancedLoginFeatureEnabled
              && !isAuthenticated
              && (to.name === 'login' || to.name === 'create-account')
            ) {
              next({
                name: 'sign-in',
                query: to.query,
              });
              return;
            }

            const guestOnlyPath = to.meta && to.meta.guestOnly;
            if ((!guestOnlyPath && isAuthenticated) || (guestOnlyPath && !isAuthenticated)) {
              next();
              return;
            }

            const guestPath = to.meta && to.meta.guest;
            if ((!guestPath && isAuthenticated) || (guestPath && !isAuthenticated)) {
              next();
              return;
            }

            if (guestPath && isAuthenticated) {
              next({ path: '/' });
            }

            if (to.name !== 'create-password') {
              if (isCommunityEnhancedLoginFeatureEnabled) {
                next({
                  name: 'sign-in',
                  query: !to.query.redirect ? { redirect: to.fullPath } : to.query,
                });
              } else {
                next({
                  name: 'login',
                  query: !to.query.redirect ? { redirect: to.fullPath } : to.query,
                });
              }
            } else {
              next();
            }
          });
      });

      router.afterEach((to, from) => {
        if (from.meta && from.meta?.routerKey) {
          let pos = {
            x: 0,
            y: window.scrollY,
          };
          store.commit('setSavedPositions', {
            key: from.meta.routerKey,
            pos,
          });
          if (from.meta?.childTab && store.getters.savedPositions.has(from.meta?.routerKey || '')) {
            pos = store.getters.savedPositions.get(from.meta?.routerKey || '');
          }
          from.meta.savedPosition = pos;
        }
        const version = ClientStorage.getItem('version') || '';
        if (version) {
          Object.assign(to.query, { version });
          window.history.replaceState(null,
            '',
            router.resolve({
              path: to.path,
              params: to.params,
              query: to.query,
            }).href);
        }
        if (to.meta?.translatedAlias) {
          const aliases = to.meta.translatedAlias;
          const localized = aliases[i18n.locale];
          if (localized && to.path !== localized) {
            router.replace({
              path: to.meta.translatedAlias[i18n.locale],
              params: to.params,
              query: to.query,
            });
          }

          let lang = LocaleService.defaultLocale;
          Object.keys(aliases)
            .forEach((key) => {
              if (aliases[key] === to.path) {
                lang = key;
              }
            });
          if (lang && lang !== i18n.locale) {
            promise = store.dispatch('switchLang', lang);
          }
        }
        if (to.query.token) {
          const query = { ...router.currentRoute.query };
          delete query.token;

          router.replace({
            path: to.path,
            params: to.params,
            query,
          });
        }

        if (to.meta && to.meta.entityType === 'Map') {
          router.replace(router.currentRoute.fullPath)
            .catch((error) => {
              if (error.name !== 'NavigationDuplicated') {
                throw error;
              }
            });
        }
      });

      promise.then(() => {
        new Vue({
          i18n,
          router,
          store,
          render: (h: CreateElement): VNode => h(App),
        }).$mount('#app');
      });
    } else {
      new Vue({
        i18n,
        router,
        store,
        render: (h: CreateElement): VNode => h(App),
      }).$mount('#app');
      router.push({ name: 'no-community' });
    }
  });
