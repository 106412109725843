import { mergeDeep, unflatten } from '@/utils/ObjectHelpers';
import BaseService from '@/services/BaseService';
import LocaleMessageRepository from '@/repositories/LocaleMessageRepository';
import AppTranslationRepository from '@/repositories/AppTranslationRepository';
import RootState from '@/store/states/RootState';
import ClientStorage from '@/utils/ClientStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

class LocaleService extends BaseService {
  static default = '';

  static fallback = '';

  private readonly appTranslationRepository: AppTranslationRepository;

  private readonly localeMessageRepository: LocaleMessageRepository;

  constructor(private state: RootState, private getters: Record<string, object | boolean>) {
    super();

    this.appTranslationRepository = new AppTranslationRepository();
    this.localeMessageRepository = new LocaleMessageRepository();
  }

  static get defaultLocale(): string {
    if (ClientStorage.getItem('locale')) {
      return ClientStorage.getItem('locale') as string;
    }
    return this.default;
  }

  static get fallbackLocale(): string {
    return this.fallback;
  }

  setLocale(lang: string): void {
    ClientStorage.setItem('locale', lang);
    if (lang && lang !== this.state.defaultLocale) {
      LocaleService.default = this.state.defaultLocale;
      LocaleService.fallback = this.state.fallbackLocale;
      document.documentElement.lang = lang;
      window.location.reload();
    }
  }

  messages(locale: string): Promise<{}> {
    return this.localeMessageRepository.all()
      .then((locales) => {
        const filter = {
          schemaCode: this.getters.communityCode,
          uiPages: null,
          // eslint-disable-next-line @typescript-eslint/camelcase
          lookup_not_starts_with: 'notification.',
          locale,
        };

        if (Object.keys(locales).find((k) => locale === k)) {
          return this.localeMessageRepository.get(locale)
            .then((response) => this.appTranslationRepository.filter({
              operationName: 'LoadAppTranslations',
              definition: buildQueryDefinition({
                cacheable: true,
                filter: {
                  value: filter,
                  type: GqlEntityFilterType.APP_TRANSLATION_FILTER,
                },
              }),
            }).then((translations) => {
              const json: Record<string, string> = {};
              translations.forEach((translation): void => {
                json[translation.lookup] = translation.content;
              });
              return mergeDeep(response, unflatten(json) || {});
            }));
        }
        return this.appTranslationRepository.filter({
          operationName: 'LoadAppTranslations',
          definition: buildQueryDefinition({
            cacheable: true,
            filter: {
              value: filter,
              type: GqlEntityFilterType.APP_TRANSLATION_FILTER,
            },
          }),
        }).then((translations) => {
          const json: Record<string, string> = {};
          translations.forEach((translation): void => {
            json[translation.lookup] = translation.content;
          });
          return unflatten(json);
        });
      });
  }
}

export default LocaleService;
