import BaseModel from '@/models/BaseModel';
/* eslint-disable import/no-cycle */
import Exhibitor from '@/models/graphql/Exhibitor';
import Geozone from '@/models/graphql/Geozone';
import SpeakerRole from '@/models/graphql/SpeakerRole';
import Handout from '@/models/graphql/Handout';
import SponsorRole from '@/models/graphql/SponsorRole';
import CommunityUserLink from '@/models/graphql/CommunityUserLink';
import Category from '@/models/graphql/Category';
import Product from '@/models/graphql/Product';
import FileResource from '@/models/graphql/FileResource';
import Edition from '@/models/graphql/Edition';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import Poll from '@/models/graphql/Poll';
import SessionRating from '@/models/graphql/SessionRating';
import Survey from '@/models/graphql/Survey';
import CommunityUser from '@/models/graphql/CommunityUser';

export default class Session extends BaseModel {
  id?: number;

  name?: string;

  edition?: Edition;

  description?: string;

  foreignId?: string;

  deleted?: boolean;

  sortValue?: string;

  descriptionHtml?: string;

  customRef1?: string;

  activityType?: string;

  publicationTime?: Date;

  startTime?: string;

  endTime?: string;

  startTimestamp?: number;

  endTimestamp?: number;

  breakStartTime?: Date;

  breakEndTime?: Date;

  registrationRequired?: boolean;

  free?: boolean;

  price?: string;

  credits?: string;

  creditsUnit?: string;

  estimatedAttendance?: string;

  audience?: string;

  subEdition?: string;

  speakers?: string;

  creationTime?: Date;

  updateTime?: Date;

  exhibitor?: Exhibitor;

  geozone?: Geozone;

  geozonesOfEdition: Geozone[] = [];

  categories?: Array<Category>;

  categoriesInContext?: Array<Category>;

  product?: Product;

  products?: Array<Product>;

  childSessions: Array<Session> = [];

  parentSession?: Session;

  speakerRoles?: Array<SpeakerRole>;

  sponsorRoles?: Array<SponsorRole>;

  handouts?: Array<Handout>;

  polls?: Array<Poll>;

  linked?: Array<CommunityUserLink>;

  _isBookmarked?: string | null;

  _isFollowed?: string | null;

  _isShared?: string | null;

  _isInAgenda?: string | null;

  _isConnected?: boolean;

  _isRecommendedForMe?: boolean;

  _bookmarkCount?: number;

  __typename?: string;

  _myTags?: CommunityUserTag[];

  status?: string;

  imageFileResource?: FileResource;

  bannerFileResource?: FileResource;

  featured?: boolean;

  showLiveChat?: boolean;

  showQNA?: boolean;

  showPolls?: boolean;

  videoType?: 'recorded' | 'live';

  videoEnabled?: boolean;

  videoEnabledStartTime?: string | null;

  videoEnabledEndTime?: string | null;

  videoLiveEnabled?: boolean;

  videoArchiveEnabled?: boolean;

  videoLiveEndpoint?: string;

  videoArchiveEndpoint?: string;

  videoLiveEmbedEndpoint?: string;

  videoArchiveEmbedEndpoint?: string;

  videoLiveRTMPEndpoint?: string;

  videoLiveRTMPKey?: string;

  rating?: SessionRating;

  ratings?: SessionRating[];

  survey?: Survey;

  surveyUrl?: string;

  _actions?: { key: string; value: string }[];

  likeCount?: number;

  dislikeCount?: number;

  loveCount?: number;

  startDate: string | null = null;

  moderators: CommunityUser[] = [];

  get mainPicture(): string {
    return FileResourceHelper.getFullPath(this.imageFileResource, 'w40');
  }

  get isLiveSession(): boolean {
    return !!(this.videoEnabled
      && this.videoLiveEnabled
      && this.videoType === 'live'
      && (this.videoLiveEndpoint || this.videoLiveEmbedEndpoint));
  }

  get isRecordedSession(): boolean {
    return !!(this.videoEnabled
      && this.videoArchiveEnabled
      && this.videoType === 'recorded'
      && (this.videoArchiveEndpoint || this.videoArchiveEmbedEndpoint));
  }

  static hydrate(item: object): Session {
    const result = new Session();
    Object.assign(result, item);

    return result;
  }
}
