import WidgetBaseStore from '@/store/widget/WidgetBaseStore';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { Data } from '@/utils/types/WidgetData';
import GqlComposeQueryDefinitionParams from '@/utils/types/gql/GqlComposeQueryDefinitionParams';
import EntityType from '@/utils/enums/EntityType';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import { ActionButtonWidgetHelper } from '@/utils/helpers/widgets/ActionButtonWidgetHelper';
import { deepSearch } from '@/utils/ObjectHelpers';

@Module({ namespaced: true, stateFactory: true })
export default class GenericPageHeaderWidgetStore extends WidgetBaseStore {
  private genericPageHeaderDataValues: Data = {};

  private actionButtonsDataValues: { value: Data; key: string }[] = [];

  @Action
  setDataConfigs(): void {
    const { entityCode, type } = JSON.parse(this.widget?.payload || '{}');
    const configs: Array<GqlComposeQueryDefinitionParams> = [];
    let fragmentName = '';
    let operation = '';
    let filterType = '';

    // eslint-disable-next-line no-nested-ternary
    const entityType = type === 'magazineArticle'
      ? EntityType.ARTICLE.toLowerCase()
      : type === 'userProfile'
        ? EntityType.USER
        : type;
    const variables = { filter: { uid: type === 'userProfile' ? '%authUser%' : entityCode } };

    switch (entityType.toLowerCase()) {
      case EntityType.EXHIBITOR.toLocaleLowerCase():
        fragmentName = 'exhibitorGenericPageHeaderFragment';
        operation = 'exhibitor';
        filterType = GqlEntityFilterType.EXHIBITOR_FILTER;
        break;
      case EntityType.DEAL.toLocaleLowerCase():
        fragmentName = 'dealGenericPageHeaderFragment';
        operation = 'deal';
        filterType = GqlEntityFilterType.DEAL_FILTER;
        break;
      case EntityType.PRODUCT.toLocaleLowerCase():
        fragmentName = 'productGenericPageHeaderFragment';
        operation = 'product';
        filterType = GqlEntityFilterType.PRODUCT_FILTER;
        break;
      case EntityType.LARGE_PRODUCT.toLocaleLowerCase():
        fragmentName = 'largeProductGenericPageHeaderFragment';
        operation = 'largeProduct';
        filterType = GqlEntityFilterType.LARGE_PRODUCT_FILTER;
        break;
      case EntityType.USER.toLowerCase():
        fragmentName = 'communityUserGenericPageHeaderFragment';
        operation = 'communityUser';
        filterType = GqlEntityFilterType.COMMUNITY_USER_FILTER;
        break;
      case EntityType.SPEAKER.toLowerCase():
        fragmentName = 'speakerGenericPageHeaderFragment';
        operation = 'speaker';
        filterType = GqlEntityFilterType.SPEAKER_FILTER;
        break;
      case EntityType.SESSION.toLowerCase():
        fragmentName = 'sessionGenericPageHeaderFragment';
        operation = 'session';
        filterType = GqlEntityFilterType.SESSION_FILTER;
        break;
      case EntityType.SUB_EDITION.toLowerCase():
        fragmentName = 'subEditionGenericPageHeaderFragment';
        operation = 'subEdition';
        Object.assign(variables, { filter: { code: entityCode } });
        filterType = GqlEntityFilterType.SUB_EDITION_FILTER;
        break;
      case EntityType.ARTICLE.toLowerCase():
        fragmentName = 'articleGenericPageHeaderFragment';
        operation = 'article';
        filterType = GqlEntityFilterType.ARTICLE_FILTER;
        break;
      case 'magazineArticle':
        fragmentName = 'articleMagazineFragment';
        operation = 'article';
        filterType = GqlEntityFilterType.ARTICLE_FILTER;
        break;
      case EntityType.CHANNEL.toLowerCase():
        fragmentName = 'channelGenericPageHeaderFragment';
        operation = 'channel';
        filterType = GqlEntityFilterType.CHANNEL_FILTER;
        break;
      case EntityType.TOPIC.toLowerCase():
        fragmentName = 'topicGenericPageHeaderFragment';
        operation = 'topic';
        filterType = GqlEntityFilterType.TOPIC_FILTER;
        break;
      case EntityType.CATEGORY.toLowerCase():
        fragmentName = 'categoryGenericPageHeaderFragment';
        operation = 'category';
        filterType = GqlEntityFilterType.CATEGORY_FILTER;
        break;
      default:
        fragmentName = '';
        operation = '';
        filterType = '';
        break;
    }
    if (operation && fragmentName) {
      configs.push({
        gqlDefinition: buildQueryDefinition({
          cacheable: !this.context.rootGetters.authUser
            || (!deepSearch(variables.filter, this.context.rootGetters.authUser.uid)
              && !deepSearch(variables.filter, '%authUser%')),
          filter: {
            value: variables.filter,
            type: filterType,
          },
        }),
        fragmentName,
        operation,
        alias: this.widgetStoreName,
      });
    }

    if (this.widget?.subWidgets) {
      configs.push(
        ...ActionButtonWidgetHelper.constructActionButtonsQueries(
          // eslint-disable-next-line max-len
          this.widget.subWidgets, entityType, entityCode, this.widgetStoreName, this.context.rootState.community?.code, this.context,
        ),
      );
    }
    super.setDataConfigs(configs);
  }

  @Mutation
  setData(data: { values: Array<Data>; key: string }): void {
    if (data.values.length) {
      if (data.key.includes('_')) {
        this.actionButtonsDataValues = [...this.actionButtonsDataValues, { value: data.values[0], key: data.key }];
      } else {
        [this.genericPageHeaderDataValues] = data.values;
      }
    }
  }

  @Mutation
  mapper(): void {
    this.mapping = {
      ...this.payload,
      data: this.genericPageHeaderDataValues,
      actionButtons: this.widget ? ActionButtonWidgetHelper.mapActionButton(this.widget, this.actionButtonsDataValues) : [],
    };
  }
}
