var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    !_vm.isUnifyExhibitorPortal ? 'app-container' : 'app-exhibitor-portal',
    _vm.pageClasses,
    {'authenticated': _vm.isAuthenticated}
  ],attrs:{"id":"app"}},[(_vm.isAuthenticated
      && _vm.authUser
      && _vm.$route.meta.guestOnly
      && _vm.$route.name
      && !['sign-in', 'login'].includes(_vm.$route.name))?_c('not-found'):_c('router-view',{style:(_vm.isUnifyExhibitorPortal && _vm.breakpoint.value === 'xl'
      ? 'width: calc(100% - var(--side-menu-width)); margin-left: auto;'
      : ''),attrs:{"is-close":_vm.isMessageBoxClose}}),(_vm.showHeader)?_c('router-view',{style:(_vm.isUnifyExhibitorPortal && _vm.breakpoint.value === 'xl'
      ? 'width: calc(100% - var(--side-menu-width)); left: var(--side-menu-width);'
      : ''),attrs:{"name":"mainHeader"}}):_vm._e(),_c('div',{staticClass:"tab-menu-header"}),(_vm.showSideMenu)?_c('router-view',{attrs:{"name":"sideMenu"}}):_vm._e(),(_vm.isAuthenticated && _vm.showToolbar)?_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTheRestOfThePage),expression:"showTheRestOfThePage"}],attrs:{"name":"toolbar"}}):_vm._e(),(_vm.isAuthenticated && _vm.isChatPanelFeatureEnabled && _vm.showChatPanel)?_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTheRestOfThePage && (!_vm.isMessageBoxClose || _vm.breakpoint.value === 'xl')),expression:"showTheRestOfThePage && (!isMessageBoxClose || breakpoint.value === 'xl')"}],attrs:{"name":"message"}}):_vm._e(),(_vm.showFooter)?_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTheRestOfThePage),expression:"showTheRestOfThePage"}],attrs:{"guest-user":_vm.$route.meta.guestOnly,"name":"footer"}}):_vm._e(),(_vm.isAuthenticated && _vm.showToolbar)?_c('toolbox-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTheRestOfThePage),expression:"showTheRestOfThePage"}]}):_vm._e(),(_vm.isAuthenticated
      && _vm.isChatPanelFeatureEnabled
      && _vm.showChatPanel
      && _vm.showTheRestOfThePage)?_c('message-box-panel',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMessageBoxClose || _vm.breakpoint.value === 'xl'),expression:"!isMessageBoxClose || breakpoint.value === 'xl'"}]}):_vm._e(),(_vm.preloadWidget
      && _vm.showCta
      && _vm.authUser
      && _vm.authUser.uid)?_c('notification-box',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTheRestOfThePage),expression:"showTheRestOfThePage"}],attrs:{"entity-type":_vm.entityPageType,"entity-uid":_vm.entityPageUid}}):_vm._e(),(_vm.preloadWidget && _vm.showGdpr)?_c('gdpr-form-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTheRestOfThePage),expression:"showTheRestOfThePage"}]}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }